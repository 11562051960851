import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withPrefix } from 'gatsby';
import { LayoutWrapper } from '@cof/lighthouse-component-library';
import { Tile } from '@cof/lighthouse-component-library';
import { TextBlock } from '@cof/lighthouse-component-library';
import { ReviewCard } from '@cof/lighthouse-component-library';
import { Accordion } from '@cof/lighthouse-component-library';
import { AccordionItem } from '@cof/lighthouse-component-library';
import { Link } from '@cof/lighthouse-component-library';
import Loading from '@cof/graffiti-alley-spray-cans/atoms/Loading';

import { LayoutPDP } from '../../components/LayoutPDP';
import Header from '../../components/HeaderLH';
import PdpFooterLH from '../../components/Pdp/PdpFooterLH';
import PdpFees from '../../components/Pdp/PdpFees';
import FlipCardSetLH from '../../components/FlipCardSetLH/FlipCardSetLH';
import IconPDP from '../../components/Pdp/IconPdp';
import IconSetPDP from '../../components/Pdp/IconSetPdp';
import CardTileLearnMoreLH from '../../components/CardTileLearnMoreLH';
import CardTileSecureLH from '../../components/CardTileSecureLH';
import VerticalContentPDP from '../../components/VerticalContentPDP';
import StickyBar from '../../components/StickyBar';
import GuaranteedMastercardLegalContent from '../../components/ModalContent/GuaranteedMastercardLegalContent';
import { productApiErrorHandler } from '../../lib/api';
import { getContentKeyUtil } from '../../lib/utils';
import SingleCtaButton from '../../components/SingleCtaButton';
import Ratings from '../../components/Ratings/Ratings';
import { useCardDetails } from '../../hooks/useCardDetails';

import travelCardArt from '../../assets/images/aspire-travel-card-art.png';
import pdpIconStepOne from '../../assets/images/pdp-icon-apply.svg';
import pdpIconStepTwo from '../../assets/images/pdp-icon-security.svg';
import pdpIconStepThree from '../../assets/images/pdp-icon-credit.svg';
import pdpIconSupport from '../../assets/images/pdp-icon-support.svg';
import pdpIconChat from '../../assets/images/pdp-icon-chat.svg';
import pdpIconAgent from '../../assets/images/pdp-icon-agent.svg';
import verticalContentDesktop from '../../assets/images/vertical-content-desktop-en.png';
import verticalContentTablet from '../../assets/images/vertical-content-tablet-en.png';
import verticalContentMobile from '../../assets/images/vertical-content-mobile-en.png';
import verticalContentDesktopFr from '../../assets/images/vertical-content-desktop-fr.png';
import verticalContentTabletFr from '../../assets/images/vertical-content-tablet-fr.png';
import verticalContentMobileFr from '../../assets/images/vertical-content-mobile-fr.png';
import greenCheck from '../../assets/images/green-check.svg';
import marketingImage from '../../assets/images/marketing-atp.png';
import secureGiftIcon from '../../assets/images/pdp-secure-notification.svg';
import SEO from '../../components/SEO/SEO';
import './aspire-travel-platinum.scss';
import CardTilesLH from '../../components/CardTilesLH';

const singleCtaLink = '/quickcheck/';
const brand = 'atp';
const getContentKey = getContentKeyUtil('pages.aspire-travel');

function getImagePerLanguage(intl, enImage, frImage) {
  return intl.locale === 'fr' ? frImage : enImage;
}

const AspireTravelHeroBanner = () => {
  return (
    <div className="aspire-travel-container">
      <div className="aspire-travel-content">
        <TextBlock
          headerTag="h1"
          headerText={<FormattedMessage id="pages.aspire-travel.pdp-banner.header" />}
          descriptionText={<FormattedMessage id="pages.aspire-travel.pdp-banner.description" />}
          descriptionFontSizeAs="h1"
        />
        <p className="sub-header">
          <FormattedMessage
            id="pages.aspire-travel.pdp-banner.sub-header"
            values={{
              sup1: <sup>1</sup>,
              boldText: (
                <b>
                  <FormattedMessage
                    id={'pages.aspire-travel.pdp-banner.sub-header'.replace(/sub-header$/, 'values.boldText')}
                  />
                </b>
              ),
            }}
          />
        </p>
        <div className="aspire-travel-image-box">
          <img src={travelCardArt} alt="" className="aspire-travel-desktop" />
        </div>
        <SingleCtaButton brand={brand} url={singleCtaLink} shouldDisplaySentencedCase useRedesignLink />
        <Ratings brand="atp" legal="2" />
      </div>
      <div className="aspire-travel-image-box-desktop">
        <img src={travelCardArt} alt="" className="aspire-travel-desktop" />
      </div>
    </div>
  );
};

const CardRequirements = ({ intl }) => {
  return (
    <section className="card-requirements">
      <div className="left-content">
        <div className="left-content-info">
          <h2>
            <FormattedMessage id="pages.aspire-travel.card-requirements.title" />
          </h2>
          <p>
            <FormattedMessage
              id="pages.aspire-travel.card-requirements.description"
              values={{
                sign: (
                  <Link to={intl.locale === 'fr' ? '/sign-in/fr' : '/sign-in'} className="card-requirements-learn">
                    <FormattedMessage id="pages.aspire-travel.card-requirements.sign" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>
      <div className="right-content">
        <ol>
          <li>
            <img src={greenCheck} alt="" />
            <h3>{intl.formatMessage({ id: `pages.aspire-travel.card-requirements.p1` })}</h3>
            <p>{intl.formatMessage({ id: `pages.aspire-travel.card-requirements.desc1` })}</p>
          </li>
          <li>
            <img src={greenCheck} alt="" />
            <h3>{intl.formatMessage({ id: `pages.aspire-travel.card-requirements.p2` })}</h3>
            <p>
              <FormattedMessage
                id="pages.aspire-travel.card-requirements.desc2"
                values={{
                  sup4: <sup>4</sup>,
                }}
              />
            </p>
          </li>
          <li>
            <img src={greenCheck} alt="" />
            <h3>{intl.formatMessage({ id: `pages.aspire-travel.card-requirements.p3` })}</h3>
            <p>{intl.formatMessage({ id: `pages.aspire-travel.card-requirements.desc3` })}</p>
          </li>
        </ol>
      </div>
    </section>
  );
};

CardRequirements.propTypes = {
  intl: intlShape,
};

const MarketingSection = ({ intl }) => {
  return (
    <div className="marketing-section-container">
      <TextBlock
        descriptionTag="h2"
        containerClassName="marketing-text"
        descriptionFontSizeAs="h2"
        descriptionText={intl.formatMessage({ id: 'pages.aspire-travel.marketing-section.title' })}
        contentText={intl.formatMessage({ id: 'pages.aspire-travel.marketing-section.description' })}
      />
      <img src={marketingImage} className="marketing-image" alt="" />
    </div>
  );
};

MarketingSection.propTypes = {
  intl: intlShape,
};

const ReviewsBanner = ({ intl }) => {
  const reviewCardContent = (
    <>
      <span className="gray-text testimonial-desktop">
        {intl.formatMessage({ id: 'pages.aspire-travel.reviews.author' })} –{' '}
        {intl.formatMessage({ id: 'pages.aspire-travel.reviews.customer' })}
      </span>
      <span className="gray-text testimonial-mobile">
        {intl.formatMessage({ id: 'pages.aspire-travel.reviews.author' })}
      </span>
      <span className="gray-text testimonial-mobile">
        {intl.formatMessage({ id: 'pages.aspire-travel.reviews.customer' })}
      </span>
    </>
  );
  return (
    <section className="pdp-reviews-banner">
      <h2>{intl.formatMessage({ id: 'pages.aspire-travel.reviews.title' })}</h2>
      <div className="pdp-reviews-content">
        <div className="pdp-reviews-content-box">
          <ReviewCard
            className="review-card-no-stars"
            author={reviewCardContent}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.aspire-travel.reviews.testimonial' })}
          />
        </div>
      </div>
      <div className="review-card-container">
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.aspire-travel.reviews.a1' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.aspire-travel.reviews.t1' })}
          />
        </div>
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.aspire-travel.reviews.a2' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.aspire-travel.reviews.t2' })}
          />
        </div>
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.aspire-travel.reviews.a3' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.aspire-travel.reviews.t3' })}
          />
        </div>
      </div>
    </section>
  );
};

ReviewsBanner.propTypes = {
  intl: intlShape,
};

const LegalDisclaimer = ({ intl }) => {
  const downloadImage = (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 16.3388V13.9388H6.40002V17.9388H17.6V13.9388H16V16.3388H8.00002Z"
        fill="#255F82"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23431 11.3045L9.36568 10.1732L12 12.8075L14.6343 10.1732L15.7657 11.3045L12 15.0702L8.23431 11.3045Z"
        fill="#255F82"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2 13.9389V6.73889H12.8V13.9389H11.2Z" fill="#255F82" />
    </svg>
  );
  const links = [
    {
      text: 'pages.aspire-travel.legal-disclaimer.document1.text',
      url: 'pages.aspire-travel.legal-disclaimer.document1.url',
    },
    {
      text: 'pages.aspire-travel.legal-disclaimer.document2.text',
      url: 'pages.aspire-travel.legal-disclaimer.document2.url',
    },
    {
      text: 'pages.aspire-travel.legal-disclaimer.document3.text',
      url: 'pages.aspire-travel.legal-disclaimer.document3.url',
    },
    {
      text: 'pages.aspire-travel.legal-disclaimer.document4.text',
      url: 'pages.aspire-travel.legal-disclaimer.document4.url',
    },
  ];

  return (
    <section id="legal-disclaimer-content">
      <h2>
        <FormattedMessage id="pages.aspire-travel.legal-disclaimer.documents" />
      </h2>
      <div className="links">
        {links &&
          links.map(({ text, url }) => (
            <a key={text} href={withPrefix(intl.formatMessage({ id: url }))} target="_blank" rel="noopener noreferrer">
              <div>{downloadImage}</div>
              <FormattedMessage
                id={text}
                values={{
                  boldText: (
                    <b>
                      <FormattedMessage id={text.replace(/text$/, 'values.boldText')} />
                    </b>
                  ),
                }}
              />
            </a>
          ))}
      </div>
      <AccordionItem
        title={<h3>{getContentKey(intl, `legal-disclaimer.title`)}</h3>}
        id="legal-info-accordion"
        onFocus={() => {}}
        onBlur={() => {}}
        isIndependent
      >
        <GuaranteedMastercardLegalContent brand="atp" intl={intl} />
      </AccordionItem>
    </section>
  );
};

LegalDisclaimer.propTypes = {
  intl: intlShape,
};

const Faq = ({ intl }) => {
  const itemProps = [
    {
      title: <h3>{getContentKey(intl, 'faq.q1')}</h3>,
      children: <p>{getContentKey(intl, 'faq.p1')}</p>,
    },
    {
      title: <h3>{getContentKey(intl, 'faq.q2')}</h3>,
      children: (
        <p>
          <FormattedMessage id="pages.aspire-travel.faq.p2-1" values={{ sup1: <sup>1</sup>, MC: <sup>MC</sup> }} />
          <br />
          <br />
          <FormattedMessage id="pages.aspire-travel.faq.p2-2" values={{ sup1: <sup>1</sup> }} />
        </p>
      ),
    },
  ];

  return (
    <>
      <h2 id="faq" className="faq-title">
        {getContentKey(intl, 'faq.title')}
      </h2>
      <Accordion itemProps={itemProps} />
    </>
  );
};

Faq.propTypes = {
  intl: intlShape,
};

const AspireTravelPlatinumPage = injectIntl(({ intl }) => {
  const { isLoading, isError, snippetDetails } = useCardDetails({
    intl,
    brand,
    snippetNames: ['annual-fee', 'purchase', 'cash-advance', 'balance-transfer'],
    options: { type: 'detail' },
    errorHandler: productApiErrorHandler,
    isErrorBoundaried: false,
  });

  if (isLoading) {
    return <Loading />;
  }

  const aspireTravelHeader = () => {
    return <Header intl={intl} />;
  };

  const aspireTravelFooter = () => {
    return <PdpFooterLH intl={intl} promoSuper={5} />;
  };

  return (
    <LayoutPDP
      containerClass="aspire-travel-container"
      CustomHeader={aspireTravelHeader}
      CustomFooter={aspireTravelFooter}
    >
      <LayoutWrapper className="aspire-travel-banner-wrapper-atp">
        <AspireTravelHeroBanner />
      </LayoutWrapper>

      <LayoutWrapper className="card-tiles-layout-wrapper-atp">
        <CardTilesLH contentKeyPrefix="aspire-travel" />
      </LayoutWrapper>

      {isError === false && (
        <LayoutWrapper className="fees-layout-wrapper-atp pdp-gray-background">
          <PdpFees intl={intl} feeSnippets={snippetDetails} brand={brand} />
        </LayoutWrapper>
      )}

      <LayoutWrapper className="icon-set-layout-wrapper-atp">
        <div className="pdp-icon-set-container">
          <h2>{getContentKey(intl, 'how-it-works.title')}</h2>
          <IconSetPDP>
            <IconPDP
              caption={getContentKey(intl, 'how-it-works.step1')}
              subCaption={getContentKey(intl, 'how-it-works.p1')}
              icon={pdpIconStepOne}
              iconAlt=""
              bolded="bottom"
            />
            <IconPDP
              caption={getContentKey(intl, 'how-it-works.step2')}
              subCaption={getContentKey(intl, 'how-it-works.p2')}
              icon={pdpIconStepTwo}
              iconAlt=""
              bolded="bottom"
            />
            <IconPDP
              caption={getContentKey(intl, 'how-it-works.step3')}
              subCaption={getContentKey(intl, 'how-it-works.p3')}
              icon={pdpIconStepThree}
              iconAlt=""
              bolded="bottom"
            />
          </IconSetPDP>
        </div>
      </LayoutWrapper>

      <LayoutWrapper className="card-requirements-wrapper-atp pdp-gray-background">
        <CardRequirements intl={intl} />
      </LayoutWrapper>

      <LayoutWrapper className="marketing-layout-wrapper-atp pdp-gray-background">
        <MarketingSection intl={intl} />
      </LayoutWrapper>

      <LayoutWrapper className="flip-card-set-layout-wrapper-atp pdp-gray-background">
        <FlipCardSetLH />
      </LayoutWrapper>

      <LayoutWrapper className="reviews-layout-wrapper-atp pdp-gray-background">
        <ReviewsBanner wrapperC intl={intl} />
      </LayoutWrapper>

      <LayoutWrapper className="credit-vertical-contents-card-layout-wrapper-atp pdp-gray-background">
        <VerticalContentPDP
          titleHeadingTag="h2"
          titleText={getContentKey(intl, 'vertical.title')}
          content={
            window.innerWidth >= 1024 ? (
              <FormattedMessage id="pages.aspire-travel.vertical.description" values={{ break: <br /> }} />
            ) : (
              <FormattedMessage id="pages.aspire-travel.vertical.description" values={{ break: ' ' }} />
            )
          }
          image={
            window.innerWidth >= 1024
              ? getImagePerLanguage(intl, verticalContentDesktop, verticalContentDesktopFr)
              : window.innerWidth >= 640
              ? getImagePerLanguage(intl, verticalContentTablet, verticalContentTabletFr)
              : getImagePerLanguage(intl, verticalContentMobile, verticalContentMobileFr)
          }
          imageAlt=""
        />
      </LayoutWrapper>

      <LayoutWrapper className="card-tile-secure-layout-wrapper-atp pdp-gray-background">
        <CardTileSecureLH
          titleLeft={intl.formatMessage({ id: 'pages.aspire-travel.card-tiles.c1' })}
          descriptionLeft={intl.formatMessage({ id: 'pages.aspire-travel.card-tiles.p1' })}
          titleRight={intl.formatMessage({ id: 'pages.aspire-travel.card-tiles.c2' })}
          descriptionRight={intl.formatMessage({ id: 'pages.aspire-travel.card-tiles.p2' })}
          imgRight={secureGiftIcon}
        />
      </LayoutWrapper>

      <LayoutWrapper className="learn-more-wrapper-atp pdp-gray-background">
        <CardTileLearnMoreLH brand="atp" />
      </LayoutWrapper>

      <LayoutWrapper className="icon-set-layout-wrapper-atp pdp-gray-background">
        <div className="pdp-icon-set-container">
          <h2>{getContentKey(intl, 'support.title')}</h2>
          <IconSetPDP dividers={false}>
            <IconPDP
              caption={getContentKey(intl, 'support.step1')}
              subCaption={getContentKey(intl, 'support.p1')}
              icon={pdpIconSupport}
              iconAlt=""
              bolded="top"
            />
            <IconPDP
              caption={getContentKey(intl, 'support.step2')}
              subCaption={getContentKey(intl, 'support.p2')}
              icon={pdpIconChat}
              iconAlt=""
              bolded="top"
            />
            <IconPDP
              caption={getContentKey(intl, 'support.step3')}
              subCaption={getContentKey(intl, 'support.p3')}
              icon={pdpIconAgent}
              iconAlt=""
              bolded="top"
            />
          </IconSetPDP>
        </div>
      </LayoutWrapper>

      <LayoutWrapper className="faq-wrapper-atp">
        <Faq intl={intl} />
      </LayoutWrapper>

      <LayoutWrapper
        className={`legal-disclaimer-layout-wrapper-atp ${intl.locale === 'en' ? 'extra-space-bottom' : ''}`}
      >
        <LegalDisclaimer intl={intl} />
      </LayoutWrapper>

      <StickyBar contentPosition="center" isVerticalDisplayinSmallScreen={false} stickybarPosition="bottom">
        <Tile>
          <img src={travelCardArt} alt="" />
        </Tile>
        <div className="stickybar-button" role="none">
          <SingleCtaButton brand={brand} url={singleCtaLink} useRedesignLink />
        </div>
      </StickyBar>
    </LayoutPDP>
  );
});

export default AspireTravelPlatinumPage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.aspire-travel.seo.page-title"
    descriptionKey="pages.aspire-travel.seo.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
